@import '../../components/variables.less';

#eident-error {
    text-align: center;

    .content {
        padding: 0px;
    }

    h1 {
        padding-left: @spacing;
        padding-top: @spacing * 4;
        padding-bottom: @spacing;
    }

    button {
        margin-top: @spacing * 3;
        margin-bottom: @spacing * 3;
    }
}
