@import '~semantic-ui-css/semantic.min.css';
@import './variables.less';
@import './fonts.less';

.transitionTransform() {
    transition: transform 0.2s;

    &:hover {
        transform: translateY(-2px);
    }
}

.fadeAnimation() {
    opacity: 1;
    animation: fade 0.2s;

    @keyframes fade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.newlines {
    white-space: pre-line;
}

.noWrap {
    white-space: nowrap;
}

body {
    background-color: @white;
    margin: 0;
    padding: 0;
    display: flex;
    overflow: hidden;

    font-family: @fontFamilyBasic;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: @helttiBlack;
}

h3.ui.header {
    font-family: @fontFamilyBrand;
    font-weight: 300;
    font-size: 1em;
    text-transform: uppercase;
}

h4.ui.header {
    font-family: @fontFamilyBasic;
    font-weight: 700;
    font-size: 16px;
}

h5.ui.header {
    font-family: @fontFamilyBasic;
    font-weight: 300;
    font-size: 14px;
}

.ui.header:first-child {
    @media (max-width: @tabletMaxWidth) {
        margin-top: 1rem;
    }
}

i.close.icon::before {
    cursor: pointer;
    position: absolute;
    font-size: 22px;
    color: @grayDark;
    left: -38px;
    top: 54px;
}

.ui.modals.dimmer {
    background-color: #aba9a98a;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: @grayLight;
    color: @grayMedium;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 20px;
    cursor: default;
}

button.ui.button {
    font-family: @fontFamilyBrand;
    font-weight: 300;
}

.ui.primary.button {
    background-color: @helttiBlue;

    &:hover {
        background-color: lighten(@helttiBlue, 5%);
    }

    &:active,
    &:focus {
        background-color: darken(@helttiBlue, 5%);
    }
}

.ui.table {
    cursor: pointer;
}

form.ui.form {
    .ui.input {
        input {
            background-color: @grayLight;
            border: none;

            &::placeholder {
                font-style: italic;
                font-weight: 100;
                color: lighten(@helttiBlack, 10%);
            }
        }
        input:read-only {
            cursor: not-allowed;
        }
    }

    .field label {
        font-family: @fontFamilyBasic;
        font-weight: 700;
        font-size: 14px;
    }

    .field input {
        font-family: @fontFamilyBasic;
    }

}

.ui.message .header {
    font-family: @fontFamilyBasic;
}

.ui.error.message {
    background-color: #fcdad9;
    border: 1px solid #d83a1a;

    font-family: @fontFamilyBrand;
    font-weight: 300;
    color: #d83a1a;

    cursor: default;
    box-shadow: 1px 5px 10px fade(#fcdad9, 50%);

    .fadeAnimation();
}

.ui.avatar {
    background-color: @helttiBlueLight !important;
}

#app-container {
    width: 100%;
    height: 100%;
}

#app {
    height: 100%;
    flex: 1;

    min-height: 0;

    display: flex;
    flex-direction: column;
}

#contentScroll {
    position: relative;
    flex: 1;
    overflow-y: auto;
    background-color: @backgroundColor;

    a {
        color: @helttiBlack;
        border-bottom: 1px solid @helttiBlue;

        .transitionTransform();
    }
}
