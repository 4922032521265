@import './../../components/variables.less';

#home-content {
    td > div {
        font-family: @fontFamilyBasic;
        font-weight: 300;
        font-size: 14px;
    }

    .ui.avatar {
        margin-top: 12px;
        margin-left: 5px;
        margin-right: 5px;
    }

    div.content {
        font-family: @fontFamilyBasic;
        font-weight: 700;
        font-size: 14px;
    }

    .sub.header {
        color: @helttiBlack;
        line-height: 1.4em;
    }
}
