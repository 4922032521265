#forgot-password-content {
    padding: 16px 8px 8px;

    #loginGrid {
        
        margin: 8px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }

    h3 {
        cursor: default;
        font-family: Cocogoose-Pro, sans-serif !important;
        font-weight: 300;
        letter-spacing: 1.4px;
    }

    #loginFormContainer {
        max-width: 500px;
        padding: 8px 48px;
        padding-bottom: 48px;
    }

    div.center.aligned.middle.aligned.column {
        padding: 0;
    }
}