@font-face {
    font-family: Cocogoose-Pro;
    src: url(./../assets/fonts/Cocogoose-Pro-Regular.woff) format("woff"), url(./../assets/fonts/Cocogoose-Pro-Regular.ttf) format("truetype")
}

@font-face {
    font-family: Cocogoose-Pro;
    font-weight: 300;
    src: url(./../assets/fonts/Cocogoose-Pro-SemiLight.woff) format("woff"),url(./../assets/fonts/Cocogoose-Pro-SemiLight.ttf) format("truetype")
}

@font-face {
    font-family: Cocogoose-Pro;
    font-weight: 200;
    src: url(./../assets/fonts/Cocogoose-Pro-Light.woff) format("woff"),url(./../assets/fonts/Cocogoose-Pro-Light.ttf) format("truetype")
}

@font-face {
    font-family: Cocogoose-Pro;
    font-weight: 100;
    src: url(./../assets/fonts/Cocogoose-Pro-Thin.woff) format("woff"),url(./../assets/fonts/Cocogoose-Pro-Thin.ttf) format("truetype")
}

@font-face {
    font-family: TTWalls;
    font-weight: 400;
    src: url(./../assets/fonts/TTWalls-RoughBold.woff) format("woff")
}
