@import './variables.less';

#passwordIndicator {
    margin-top: @spacing;
    text-align: left;

    p {
        margin-bottom: @spacing / 2;
        font-weight: bold;
    }

    ul {
        margin-top: 0;
        margin-left: @spacing;
        list-style: none;

        padding: 0;

        li {
            div {
                display: inline-block;
                vertical-align: top;

                &.passwordValidityCheck {
                    margin-top: 3px;
                    width: 18px;
                    height: 18px;

                    margin-right: @spacing;
                    border-radius: 50%;
                    background-color: @gray;

                    font-family: @fontFamilyBrandAlternative;
                    text-align: center;
                    line-height: 18px;
                    font-size: 16px;

                    &.passwordValidityCheckValid {
                        background-color: #44b859;
                        color: @white;
                    }
                }
            }
        }
    }
}
