@import './../../components/variables.less';

h3.ui.header {
    font-family: @fontFamilyBrand;
    font-weight: 300;
    font-size: 1em;
    text-transform: uppercase;
}

.DateRangePicker {
    width: 100%;
}
.DateRangePickerInput_arrow {
    margin: 15px;
}
.DateInput {
    width: 40%;
}
